import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { NewVersionModalComponent } from '../../utils/modals/new-version-modal/new-version-modal.component';
import { ServiceDestroyed, TakeUntilDestroy } from '../decorators/take-until-destroy';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class NewVersionService implements ServiceDestroyed, OnDestroy {
  serviceDestroyed = new Subject<void>();

  newVersionSubscription: Subscription;

  constructor(private swUpdate: SwUpdate, private dialog: MatDialog, private localStorageService: LocalStorageService) {
    this.checkForUpdate();
  }

  ngOnDestroy() {}

  checkForUpdate(): void {
    this.newVersionSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      console.log('Service worker is not enabled');
      return;
    }
    this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
      console.log('Checking for new version');
      this.localStorageService.setItem('versionEvt', evt);
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.openDialog();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          this.openDialog();
          break;
        case 'NO_NEW_VERSION_DETECTED':
          console.log(`No new app version detected: ${evt.version.hash}`);
          break;
      }
    });
  }

  openDialog(): void {
    const dialog = this.dialog.open(NewVersionModalComponent, {
      disableClose: true,
    });

    dialog.componentInstance.onReload
      .pipe(
        tap(() => this.refresh()),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  refresh(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => {
        console.log('Update activated, clearing storage...');
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.delete(cacheName);
          });
        });
        this.localStorageService.clear();
        return new Promise(resolve => setTimeout(resolve, 500));
      })
      .then(() => document.location.reload())
      .catch(error => console.error('Failed to apply updates:', error));
  }
}
